@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: orange;
}

.Header_header__2ZveA {
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #8a2b06;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.Header_header__2ZveA a {
  color: white;
  text-decoration: none;
}

.Header_header__2ZveA a:hover,
.Header_header__2ZveA a:active,
.Header_header__2ZveA a.Header_active__3VptJ {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

.HeaderCartButton_button__C6nxg {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #4d1601;
  color: white;
  padding: 0.75rem 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.HeaderCartButton_button__C6nxg:hover,
.HeaderCartButton_button__C6nxg:active {
  background-color: #2c0d00;
}

.HeaderCartButton_icon__14t6l {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.5rem;
}

.HeaderCartButton_badge__3xaab {
  background-color: #b94517;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  font-weight: bold;
}

.HeaderCartButton_button__C6nxg:hover .HeaderCartButton_badge__3xaab,
.HeaderCartButton_button__C6nxg:active .HeaderCartButton_badge__3xaab {
  background-color: #92320c;
}

.HeaderCartButton_bump__3NsJ0 {
  -webkit-animation: HeaderCartButton_bump__3NsJ0 300ms ease-out;
          animation: HeaderCartButton_bump__3NsJ0 300ms ease-out;
}

@-webkit-keyframes HeaderCartButton_bump__3NsJ0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes HeaderCartButton_bump__3NsJ0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Card_card__2Vdls {
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
}

.Input_input__1LkjG {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.5rem;
}

.Input_input__1LkjG label {
  font-weight: bold;
  margin-right: 1rem;
}

.Input_input__1LkjG input {
  width: 3rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
}

.MealItemForm_form__2hntM {
  text-align: right;
}

.MealItemForm_form__2hntM button {
  font: inherit;
  cursor: pointer;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealItemForm_form__2hntM button:hover,
.MealItemForm_form__2hntM button:active {
  background-color: #641e03;
  border-color: #641e03;
}

.MealItem_meal__3RQyB {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.MealItem_meal__3RQyB h3 {
  margin: 0 0 0.25rem 0;
}

.MealItem_mealimage__PV3Oy div {
  width: 15%;
}

.MealItem_description__3XQzu div {
  width: 400px;
}

.MealItem_mealimage__PV3Oy img {
  width: 100px;
  height: 100px;
}

.MealItem_description__3XQzu {
  font-style: italic;
}

.MealItem_price__3Zpiq {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
  font-size: 1.25rem;
  display: -webkit-flex;
  display: flex;
}

.MealItem_price__3Zpiq button {
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  background-color: #d37903;
  border: 1px solid #e2b170;
  color: black;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealItem_price__3Zpiq button:hover,
.MealItem_price__3Zpiq button:active {
  background-color: #e2b170;
  border-color: #641e03;
}

.AvailableMeals_meals__1qbJ- {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  -webkit-animation: AvailableMeals_meals-appear__1mj6T 1s ease-out forwards;
          animation: AvailableMeals_meals-appear__1mj6T 1s ease-out forwards;
}

.AvailableMeals_meals__1qbJ- ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.AvailableMeals_MealsLoading__3-XBD {
  text-align: center;
  color: white;
}

.AvailableMeals_MealsError__1Fd0p {
  text-align: center;
  color: red;
}

@-webkit-keyframes AvailableMeals_meals-appear__1mj6T {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes AvailableMeals_meals-appear__1mj6T {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.MealDetail_detailcard__IJVF4 {
  max-width: 60rem;
  width: 90%;
  margin: 4rem;
}

.MealDetail_outline__1P6mV a {
  text-decoration: none;
  color: black;
}

.MealDetail_leftside__2gyln {
  width: 35%;
}

.MealDetail_productdata__WzzfD {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MealDetail_productimage__1s-Oi {
  margin-right: 2rem;
}
.MealDetail_productimage__1s-Oi img {
  width: 20rem;
  height: 20rem;
  margin-bottom: 1rem;
  border-radius: 14px;
}

.MealDetail_productimage__1s-Oi button {
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  background-color: #d37903;
  border: 1px solid #e2b170;
  color: black;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealDetail_productimage__1s-Oi button:hover,
.MealDetail_productimage__1s-Oi button:active {
  background-color: #e2b170;
  border-color: #641e03;
}

.MealDetail_producttext___OmoC {
  width: 65%;
}

.MealDetail_producttext___OmoC h2 {
  font-family: "Berkshire Swash";
  font-size: 3rem;
  margin: 0 0 0.5rem 0;
}
.MealDetail_producttext___OmoC h5 {
  font-family: "Berkshire Swash";
  font-size: 1.5rem;
  margin: 0.2 0 0rem 0;
}
.MealDetail_producttext___OmoC h4 {
  margin: 0;
}
.MealDetail_productactions__1xHZU {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.MealDetail_productactions__1xHZU h4 {
  font-family: "Berkshire Swash";
  font-size: 2rem;
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
}

.MealDetail_productactions__1xHZU button {
  font: inherit;
  cursor: pointer;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealDetail_productactions__1xHZU button:hover,
.MealDetail_productactions__1xHZU button:active {
  background-color: #641e03;
  border-color: #641e03;
}

.CartItem_cart-item__3aRta {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 2px solid #8a2b06;
  padding: 1rem 0;
  margin: 1rem 0;
}

.CartItem_cart-item__3aRta h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.CartItem_summary__jizx9 {
  width: 20rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.CartItem_priceamount__2Zk_b {
  font-weight: bold;
  color: #363636;
}

.CartItem_sum__3bllN {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  color: #363636;
}

.CartItem_actions__33j_V {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 768px) {
  .CartItem_actions__33j_V {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.CartItem_cart-item__3aRta button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: #8a2b06;
  border: 1px solid #8a2b06;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.CartItem_cart-item__3aRta button:hover,
.CartItem_cart-item__3aRta button:active {
  background-color: #8a2b06;
  color: white;
}

.Cart_cartcard__zIxSF {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
}

.Cart_cart-items__1PFeN {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: scroll;
}

.Cart_total__w5vnm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.Cart_actions__3BjqG {
  text-align: right;
}

.Cart_actions__3BjqG button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #8a2b06;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}

.Cart_actions__3BjqG button:hover,
.Cart_actions__3BjqG button:active {
  background-color: #5a1a01;
  border-color: #5a1a01;
  color: white;
}

.Cart_actions__3BjqG .Cart_button--alt__ceJ8n {
  color: #8a2b06;
}

.Cart_actions__3BjqG .Cart_button__2ouKI {
  background-color: #8a2b06;
  color: white;
}

.Checkout_form__1QDtQ {
  margin: 1rem 0;
  height: 19rem;
  overflow: auto;
}

.Checkout_control__-bqLw {
  margin-bottom: 0.5rem;
}

.Checkout_control__-bqLw label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

.Checkout_control__-bqLw input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.Checkout_actions__gglOc {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}

.Checkout_actions__gglOc button {
  font: inherit;
  color: #5a1a01;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 2rem;
}

.Checkout_actions__gglOc button:hover,
.Checkout_actions__gglOc button:active {
  background-color: #ffe6dc;
}

.Checkout_actions__gglOc .Checkout_submit__fD28x {
  border: 1px solid #5a1a01;
  background-color: #5a1a01;
  color: white;
}

.Checkout_actions__gglOc .Checkout_submit__fD28x:hover,
.Checkout_actions__gglOc .Checkout_submit__fD28x:active {
  background-color: #7a2706;
}

.Checkout_invalid__2Iwm_ label {
  color: #ca3e51;
}

.Checkout_invalid__2Iwm_ input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}

