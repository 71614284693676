.detailcard {
  max-width: 60rem;
  width: 90%;
  margin: 4rem;
}

.outline a {
  text-decoration: none;
  color: black;
}

.leftside {
  width: 35%;
}

.productdata {
  display: flex;
  justify-content: space-between;
}

.productimage {
  margin-right: 2rem;
}
.productimage img {
  width: 20rem;
  height: 20rem;
  margin-bottom: 1rem;
  border-radius: 14px;
}

.productimage button {
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  background-color: #d37903;
  border: 1px solid #e2b170;
  color: black;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-weight: bold;
}

.productimage button:hover,
.productimage button:active {
  background-color: #e2b170;
  border-color: #641e03;
}

.producttext {
  width: 65%;
}

.producttext h2 {
  font-family: "Berkshire Swash";
  font-size: 3rem;
  margin: 0 0 0.5rem 0;
}
.producttext h5 {
  font-family: "Berkshire Swash";
  font-size: 1.5rem;
  margin: 0.2 0 0rem 0;
}
.producttext h4 {
  margin: 0;
}
.productactions {
  display: flex;
  justify-content: space-around;
}

.productactions h4 {
  font-family: "Berkshire Swash";
  font-size: 2rem;
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
}

.productactions button {
  font: inherit;
  cursor: pointer;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.productactions button:hover,
.productactions button:active {
  background-color: #641e03;
  border-color: #641e03;
}
